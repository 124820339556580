import Images from "../../assets/images/Images";

function AuthHeader({ showHeaderBackground = false }) {

    return(
        <div style={{ zIndex: 20}}
            className={'absolute h-[90px] w-full top-0 left-0 right-0 flex flex-row justify-between items-center px-[30px]'}>
            <img style={{ cursor: 'pointer' }} src={Images.MainLogoWhite} className={'w-[114px] h-[34px]'} alt={'1House Global'}/>
        </div>
    )

}

export default AuthHeader;
