import * as React from "react";
import ForgotPasswordView from "./ForgotPasswordView";
import VerifyAuthCodeView from "./VerifyAuthCodeView";
import ResetPasswordView from "./ResetPasswordView";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {MainPageProps} from "../../sign-up/components/SignUpForm";
import {useState} from "react";

const ForgotPasswordForm = ({ is_mobile }: MainPageProps) => {

    const [loading, setLoading] = useState(false);
    const [resetId, setResetId] = useState<string>('');

    const [currentStep, setCurrentStep] = useState(0);

    function handleReceivedResetId(data: any) {
        if (data) {
            setResetId(data)
            return setCurrentStep(1);
        }
    }

    function handleVerifiedCode() {
        return setCurrentStep(2);
    }

    function handlePasswordResetSuccess() {
        setCurrentStep(0);
        return window.location.reload()
    }

    return (
        <div style={{ zIndex: 4, backgroundColor: 'rgba(0,0,0,0.5)'}} className={`w-[100%] min-h-[100vh] h-auto bg-transparent ${is_mobile ? 'justify-start items-center pb-[40px]' : 'justify-center items-center'} flex flex-col  ${is_mobile ? 'px-[20px]' : ''}`}>
            {is_mobile && (
                <div className={'w-full min-h-[90px]'} />
            )}
            {currentStep === 0 && (
                <ForgotPasswordView is_mobile={is_mobile} startLoading={() => setLoading(true)} endLoading={() => setLoading(false)} proceedToNext={handleReceivedResetId} />
            )}

            { currentStep === 1 && (
                <VerifyAuthCodeView resetId={resetId} is_mobile={is_mobile} startLoading={() => setLoading(true)} endLoading={() => setLoading(false)} proceedToNext={handleVerifiedCode} />
            )}

            { currentStep === 2 && (
                <ResetPasswordView resetId={resetId} is_mobile={is_mobile} startLoading={() => setLoading(true)} endLoading={() => setLoading(false)} proceedToNext={handlePasswordResetSuccess} />
            )}
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )

}

export default ForgotPasswordForm
