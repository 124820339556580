import { Button } from "../../../../@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../../@/components/ui/dialog"
import { Input } from "../../../../@/components/ui/input"
import { Label } from "../../../../@/components/ui/label"
import {useEffect, useState} from "react";
import NetworkingAPI from "../../../../helpers/NetworkingAPI";


interface Props {
    session_details?: any,
    stream_details?: any
}

const EditSessionInfoDialog = ({session_details}:  Props) => {

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState('');

    async function saveChanges() {
        if (!title) {
            return console.log('Title required')
        }

        try {
            const payload = {
                title: title,
                description: description,
                session_id: session_details._id,
            }

            const dataResponse = await NetworkingAPI.fetchDataFullResponse('session/update', 'POST', undefined, payload);
            const result = dataResponse?.response?.data;
            if (result) {
                return window.location.reload()
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setTitle(session_details?.title)
        setDescription(session_details?.description)
    }, [session_details]);

    return(
        <Dialog>
            <DialogTrigger asChild>
                <Button style={{ zIndex: 15}} variant="outline">Edit Live Session Info</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Edit Live Session</DialogTitle>
                    <DialogDescription>
                        Make changes to your live session here. Click save when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            Title
                        </Label>
                        <Input
                            onChange={(e) => setTitle(e.target.value)}
                            id="name"
                            defaultValue={session_details ? session_details?.title : ''}
                            className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="username" className="text-right">
                            Description
                        </Label>
                        <Input
                            onChange={(e) => setDescription(e.target.value)}
                            id="username"
                            placeholder={'E.g. This live stream is for all users.'}
                            defaultValue={session_details ? session_details?.description : null}
                            className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="username" className="text-right">
                            RTMP URL
                        </Label>
                        <Input
                            disabled={true}
                            onChange={(e) => setDescription(e.target.value)}
                            id="username"
                            placeholder={'https://rtmp.castr.com/'}
                            defaultValue={"rtmps://global-live.mux.com:443/app"}
                            className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="username" className="text-right">
                            Stream Key
                        </Label>
                        <Input
                            disabled={true}
                            onChange={(e) => setDescription(e.target.value)}
                            id="username"
                            placeholder={'2ji4f0-3nn53-sdf'}
                            defaultValue={session_details ? session_details?.stream_key : null}
                            className="col-span-3"
                        />
                    </div>
                </div>
                <DialogFooter>
                    <Button onClick={() => saveChanges()} type="submit">Save changes</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )

}

export default EditSessionInfoDialog;
