let environment = 'PROD' //process.env.NEXT_PUBLIC_ENVIRONMENT || 'PROD';

export const APIBaseURL = environment === 'DEV' ? 'http://localhost:4200/api/v1/' : 'https://onehouselive-api-bb88cad87bda.herokuapp.com/api/v1/';

const NetworkingAPI = {

    async fetchData(url: string, method: string = 'GET', queryParams?: Record<string, any>, requestBody?: Record<string, any>): Promise<any> {

        let formattedURL = APIBaseURL + url
        let bearerToken;

        try {
            // Construct URL with query parameters
            bearerToken = localStorage.getItem('token');

            if (queryParams) {
                const queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
                formattedURL += `?${queryString}`;
            }

            const options: RequestInit = {
                method: method,
                headers: {
                    'origin': '*',
                    'x-requested-with': '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken ?? ''}`
                }
            };

            if (requestBody) {
                options.body = JSON.stringify(requestBody);
            }

            const response = await fetch(formattedURL, options);

            if (response.ok) {
                return await response.json();
            } else {
                console.log('Failed to fetch data:', response.statusText);
                return;
            }
        } catch (error) {
            console.log('Error fetching data:', error);
            return;
        }
    },

    async fetchDataFullResponse(url: string, method: string = 'GET', queryParams?: Record<string, any>, requestBody?: Record<string, any>): Promise<any> {
        let formattedURL = APIBaseURL + url
        let bearerToken;

        try {
            // Construct URL with query parameters
            const token = localStorage.getItem('token');
            bearerToken = token

            if (queryParams) {
                const queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
                formattedURL += `?${queryString}`;
            }

            const options: RequestInit = {
                method: method,
                headers: {
                    'origin': '*',
                    'x-requested-with': '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${bearerToken}`
                }
            };

            if (requestBody) {
                options.body = JSON.stringify(requestBody);
            }

            const response = await fetch(formattedURL, options);

            return {
                response: await response.json(),
                status: response.status,
            };
        } catch (error) {
            console.log('Error fetching data:', error);
            return;
        }
    },

};

export default NetworkingAPI;
