const VerifyPasswordPage = () => {

    return(
        <>
        </>
    )

}

export default VerifyPasswordPage;
