import React, {createContext, useContext, useEffect} from 'react';
import NetworkingAPI from "../helpers/NetworkingAPI";
import {useLogger} from "./useLogger";

interface AuthContextData {
    getUser: () => any;
    getUserId?: () => void;
    refetchUser: () => void;
    setUser: (user: any) => any;
    setToken: (user: any) => any;
    logout: () => void
}

const AuthContext = createContext<AuthContextData>({
    getUser: () => {},
    getUserId: () => {},
    refetchUser: () => {},
    setUser: (user: any) => {},
    setToken: (token: any) => {},
    logout: () => {}
});

export const AuthProvider = ({children}: any) => {

    const { logEvent } = useLogger();

    const setUser = (user: any) => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            return true
        } else {
            return undefined;
        }
    };

    const setToken = (token: any) => {
        if (token) {
            localStorage.setItem('token', token);
            return true
        } else {
            return undefined;
        }
    };

    const getUser = () => {
        const foundUser = localStorage.getItem('user');
        if (foundUser) {
            return JSON.parse(foundUser);
        } else {
            return null
        }
    };

    const getUserId =  () => {
        const foundUser = localStorage.getItem('user');
        if (foundUser) {
            return JSON.parse(foundUser)._id;
        } else {
            return undefined
        }
    };

    const refetchUser = async () => {
        try {
            const currentUser = await getUser()
            if (!currentUser) {
                return undefined;
            }
            await logEvent({event: 'app_session', target: 'opened'})
            const dataResponse = await NetworkingAPI.fetchDataFullResponse('user/session/refetch', 'POST', undefined, { userId: currentUser?._id})
            const result = dataResponse?.response?.data;
            if (result) {
                const user = result?.user;
                const token = result?.token;
                if (!user || !token) {

                    await setUser(user);
                    await setToken(token);
                }
            }
        } catch (error) {
            console.log(`Error: ${error}`)
        }
    }

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return true
    }

    useEffect(() => {
        refetchUser()
    },[]);

    return (
        <AuthContext.Provider value={{ getUser, getUserId, refetchUser, setUser, logout, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};

// Create a custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};
