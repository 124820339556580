import Header from "./Header";
import {Outlet} from "react-router-dom";
import React from "react";


const DashboardLayout = ({children}: any) => {

    return(
        <>
            <div className={'w-[100vw] h-[100vh] min-w-[100vw] safe-area max-h-[100vh] bg-white flex flex-col justify-between'}>
                <Header />
                <div className={'w-full h-full flex flex-row justify-start'}>
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default DashboardLayout;
