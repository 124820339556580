import SignInForm from "./components/SignInForm";
import {useEffect, useRef, useState} from "react";

function SignInPage() {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return(
        <div className={`w-[100%] bg-transparent ${screenWidth < 768 ? 'h-auto items-start overflow-scroll' : 'h-[100%] items-center'} flex justify-center`}>
            <SignInForm is_mobile={screenWidth < 768}/>
        </div>
    )

}

export default SignInPage;
