import Images from "../../assets/images/Images";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {Settings} from '@mui/icons-material';
import {Logout} from '@mui/icons-material';
import React, {useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import Typography from "@mui/material/Typography";
import {useLocation, useNavigate} from "react-router-dom";

function Header() {

    const { getUser } = useAuth();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const navigate = useNavigate();
    const location = useLocation();

    const { logout } = useAuth();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    function handleNavigation(slug: string) {
        navigate(slug)
    }
    async function handleUserLogout() {
        try {
            handleClose()
            logout()
            return window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function initializeUser() {

    }

    useEffect(() => {
        initializeUser()
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return(
        <div style={{ zIndex: 20}}
            className={'h-[90px] min-h-[90px] bg-white w-full flex flex-row justify-between items-center px-[30px] space-x-[20px]'}>
            <img src={Images.MainLogo} className={'w-[114px] h-[34px]'} alt={'LOGO'}/>
            {screenWidth >= 768 && (
                <>
                    {getUser()?.permissions?.includes('admin') ? (
                        <div className={'w-full flex-shrink h-[40px] flex flex-row justify-center items-center space-x-[10px]'}>
                            {DEFAULT_NAV_OPTIONS.map((option, index) => (
                                <div style={{ cursor: 'pointer' }} onClick={() => handleNavigation(option?.slug)} className={'px-[20px] flex justify-center items-center flex-col space-y-[5px]'}>
                                    <p className={` ${location.pathname === option?.slug ? 'font-medium text-black' : 'font-regular text-gray-600'} text-[15px]`}>{option?.title}</p>
                                    <div className={`w-full h-[3px] ${location.pathname === option?.slug ? 'bg-green-400' : ''}`}/>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </>
            )}
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Avatar alt={`${getUser()?.first_name}`} src={getUser()?.profile_image ? getUser()?.profile_image : null} sx={{ width: 40, height: 40 }}>{`${getUser().first_name?.at(0)}`}</Avatar>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <Avatar alt={`${getUser()?.first_name}`} src={getUser()?.profile_image ? getUser().profile_image : null}>{`${getUser().first_name?.at(0)}`}</Avatar>
                    <Typography>{getUser()?.first_name ?? ''} {getUser()?.last_name ?? ''}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Account Settings
                </MenuItem>
                <MenuItem onClick={() => handleUserLogout()}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    )

}

const DEFAULT_NAV_OPTIONS = [
    {id: 'live', title: 'Broadcast Now', slug: '/portal/live'},
    {id: 'users', title: 'Platform Users', slug: '/portal/users'},
    {id: 'streams', title: 'Manage', slug: '/portal/manage'},
]

export default Header;
