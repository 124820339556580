
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import AuthLayout from "../components/layout/AuthLayout";
import SignInPage from "../pages/auth/sign-in/SIgnInPage";
import SignUpPage from "../pages/auth/sign-up/SignUpPage";
import ForgotPasswordPage from "../pages/auth/forgot-password/ForgotPasswordPage";
import VerifyPasswordPage from "../pages/auth/verify-password/VerifyPasswordPage";
import DashboardLayout from "../components/layout/DashboardLayout";
import LiveCallPage from "../pages/dashboard/live-call/LiveCallPage";
import UsersPage from "../pages/dashboard/users/UsersPage";
import NotFoundPage from "../pages/not-found/NotFoundPage";
import React, {useEffect, useState} from "react";
import {useAuth} from "../hooks/useAuth";

const PlatformRoutes = ({ children }: any) => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [initialized, setInitialized] = useState<boolean>(false);

    const { getUser } = useAuth();

    // TODO: UNCOMMENT TO PERFORM AUTH CHECK
    async function fetchAuthStatus() {
        if (getUser) {
            const user = await getUser();
            if (user) {
                setIsAuthenticated(true)
            } else  {
                setIsAuthenticated(false)
            }
        }

        setInitialized(true)
    }

    useEffect(() => {
        fetchAuthStatus()
    },[isAuthenticated])

    return(
        <>
            <Router>
                <Routes>
                    <Route path="/" element={isAuthenticated ? <Navigate to="/portal" /> : <Navigate to="/auth/sign-in" />}/>
                    <Route path="/auth" element={isAuthenticated ? <Navigate to="/portal/live" /> : <AuthLayout />}>
                        <Route path="sign-in" element={<SignInPage />} />
                        <Route path="sign-up" element={<SignUpPage />} />
                        <Route path="forgot-password" element={<ForgotPasswordPage />} />
                        <Route path="verify-password" element={<VerifyPasswordPage />} />
                    </Route>

                    <Route path="/portal" element={isAuthenticated ? <DashboardLayout /> : <Navigate to="/auth/sign-in" />}>
                        <Route index element={<LiveCallPage />} />
                        <Route path="live" element={<LiveCallPage />} />
                        <Route path="users" element={(getUser()?.permissions ?? []).includes('admin') ? <UsersPage /> : <NotFoundPage />} />
                    </Route>
                    <Route path="*" element={<NotFoundPage />} /> {/* Fallback route for unmatched URLs */}
                </Routes>
            </Router>
        </>
    )
}

export default PlatformRoutes;
