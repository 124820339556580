import MessageBackgroundImage from '../../assets/images/global/SENDER_MESSAGE_BACKGROUND.png';
import Switch from '@mui/material/Switch';
import Images from "../../assets/images/Images";
import {useState} from "react";

interface SidebarProps {
    onOpen: () => void,
    mobile?: boolean
}

const ChatroomSidebarCollapsed = ({ onOpen, mobile = false }: SidebarProps) => {

    const [ commentsEnabled, setCommentsEnabled] = useState(false);

    const backgroundImageUrl = "../../../assets/global/SENDER_MESSAGE_BACKGROUND.png";
    const label = { inputProps: { 'aria-label': 'Size switch demo' } };

    function handleCommentsToggle(e: any) {
        const value = e.target.checked;
        setCommentsEnabled(value);
    }

    function handleSendComment() {
        if (!commentsEnabled) {
            return console.log('Comments disabled')
        }
    }

    return(
        <div className={'absolute px-[30px] max-h-[100%] top-0 bottom-0 right-0 flex-col flex justify-start overflow-hidden'}>
            <div className={'w-[100%] h-[90px] min-h-[90px] bg-black'}/>
            <div style={{zIndex: 16}}
                 className={`w-[100%] max-h-auto flex-shrink ${mobile ? 'px-[0px] pt-[15px]' : 'px-[24px] pt-[24px]'}  pb-[50px] flex flex-col justify-start`}>
                <div style={{ cursor: 'pointer' }} onClick={() => onOpen()} className={'flex flex-row items-center justify-start space-x-[10px] flex-nowrap'}>
                    {!mobile && (
                        <img src={Images.CommentsIcon} className={'w-[30px] h-[30px] min-w-[30px] min-h-[30px]'}/>
                    )}
                    <div className={'flex flex-col space-y-[-2px]'}>
                    <p className={`font-semibold text-white ${ mobile ? 'text-[14px]' : 'text-[17px]'}`}>View Comments</p>
                        <p className={`font-regular text-white ${mobile ? 'text-[10px]' : 'text-[12px]'}`}>Engage with the community</p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ChatroomSidebarCollapsed
