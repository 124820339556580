import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {useEffect, useState} from "react";

interface Props {
    show: boolean,
    liveStatus: boolean,
    handleClose: () => void,
    handleSelection: (value: any) => void
}

const ToggleLiveCallModal = ({ show = false, liveStatus = false, handleSelection, handleClose }: Props) => {

    const [liveCallModalOpen, setLiveCallModalOpen] = useState(false);

    const handleClickOpenLiveCallModal = () => {
        setLiveCallModalOpen(true);
    };

    function closeModal() {
        setLiveCallModalOpen(false);
        handleClose()
    }

    useEffect(() => {
        if (show) {
            handleClickOpenLiveCallModal()
        }
    }, [show]);

    return(
        <Dialog
            disableEscapeKeyDown={false}
            open={liveCallModalOpen}
            onClose={() => closeModal()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {liveStatus ? "End this live session?" : 'Start this live session?'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to end this live session? This will end the live stream for all of your viewers.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeModal()}>Cancel</Button>
                <Button color={'error'} onClick={() => {
                    handleSelection(true)
                    closeModal()
                }} autoFocus>
                    {liveStatus ? 'End Now' : 'Start Now'}
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default ToggleLiveCallModal
