import Alert from "@mui/material/Alert";
import {Lock, Mail, Visibility, VisibilityOff} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import {MainPageProps} from "../../sign-up/components/SignUpForm";
import {useState} from "react";
import NetworkingAPI from "../../../../helpers/NetworkingAPI";
import {useAuth} from "../../../../hooks/useAuth";

interface Props {
    is_mobile?: boolean,
    startLoading: () => void,
    endLoading: () => void,
    proceedToNext: (resetId: any) => void
}

const ForgotPasswordView = ({ is_mobile, startLoading, endLoading, proceedToNext }: Props) => {

    const { setUser, setToken } = useAuth();

    const [showPassword, setShowPassword] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('');

    async function handleSubmit() {
        // navigate('/portal')

        if (!email) {
            return setError('Email and password is required')
        }
        startLoading()
        try {
            const payload = {
                email: email,
            }

            const dataResponse = await NetworkingAPI.fetchDataFullResponse('auth/forgot-password/send', 'POST', undefined, payload);
            const result = dataResponse?.response?.data;
            const message = dataResponse?.response?.message;
            if (result) {
                setEmail('')
                endLoading()
                return proceedToNext(result)
            } else {
                endLoading()
                return setError(message ?? 'Failed')
            }
        } catch (error) {
            endLoading()
            return setError('An unexpected error occurred')
        }
    }

    function handleEmailChange(e: any) {
        setEmail(e.target.value);
    }

    return (
        <div className={`px-[30px] bg-white rounded-[20px] pt-[30px] pb-[40px] ${is_mobile ? 'mt-[0px]' : ''}`}>
            <div className={'flex flex-col items-center justify-center max-w-[390px] space-y-[5px]'}>
                <p className={`font-bold text-black ${is_mobile ? 'text-[25px]' : 'text-[30px]'} text-center`}>Forgot Password</p>
                <p className={`font-medium text-gray-500 ${is_mobile ? 'text-[15px]' : 'text-[17px]'} text-center`}>Need help accessing your 1House Global Account? Don't worry we're here to help</p>
            </div>

            <div className={'flex flex-col items-center justify-center max-w-[390px] space-y-[10px] mt-[20px]'}>
                {error ? (
                    <Alert className={'w-full'} severity="error">{error}</Alert>
                ) : null}
                <div
                    className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                    <Mail sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                    <TextField
                        onChange={(e) => handleEmailChange(e)}
                        InputProps={{disableUnderline: true}}
                        sx={{width: '100%'}}
                        id="email"
                        label="Email Address"
                        variant="standard"
                        className={'mb-[5px]'}/>
                </div>
            </div>
            <div className={'flex flex-col items-center justify-center max-w-[390px] mt-[15px]'}>
                <div onClick={() => handleSubmit()} style={{cursor: 'pointer'}}
                     className={'w-full bg-black rounded-[18px] px-[15px] py-[16px] items-center justify-center'}>
                    <p className={'font-semibold text-white text-[15px] text-center'}>Send Reset Code</p>
                </div>
            </div>
            <div className={'flex flex-col items-center justify-center max-w-[390px] mt-[20px]'}>
                <a style={{cursor: 'pointer'}} href={'/auth/sign-up'}>
                    <p className={`font-bold text-[#0F7B28] ${is_mobile ? 'text-[14px]' : 'text-[15px]'} text-center underline`}>Sign
                        Up for 1House
                        Live
                        Now</p>
                </a>
            </div>
        </div>
    )

}

export default ForgotPasswordView
