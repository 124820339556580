import React, {useEffect, useState} from 'react';
import './App.css';
import {AuthProvider, useAuth} from "./hooks/useAuth";
import {LoggerProvider} from "./hooks/useLogger";
import PlatformRoutes from "./routes/PlatformRoutes";
import { initializeApp } from "firebase/app";
import {SidebarProvider} from "./@/components/ui/sidebar";

const firebaseConfig = {
    apiKey: "AIzaSyA5i4_d_-fhizCcodADPWRPuSGX3GhCCU4",
    authDomain: "houseglobal-web.firebaseapp.com",
    projectId: "houseglobal-web",
    storageBucket: "houseglobal-web.firebasestorage.app",
    messagingSenderId: "1046462392884",
    appId: "1:1046462392884:web:d2fba79178ef55029ff861",
    measurementId: "G-7LEC90P9BY"
};

function App() {

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => setViewportHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);

        // Set the height initially
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function initializeFirebase() {
        initializeApp(firebaseConfig);
    }

    useEffect(() => {
        initializeFirebase()
    }, []);

    return (
      <AuthProvider>
          <LoggerProvider>
              <SidebarProvider
                  defaultOpen={true}
                  style={{
                      // @ts-ignore
                      "--sidebar-width": "20rem",
                      "--sidebar-width-mobile": "20rem",
                  }}
              >
                  <div style={{ height: viewportHeight }}
                       className="overflow-hidden bg-white">
                      <PlatformRoutes />
                  </div>
              </SidebarProvider>
          </LoggerProvider>
      </AuthProvider>
  );
}

export default App;
