import ChatroomSidebar from "../../../components/modals/ChatroomSidebar";
import LiveCallDescriptionView from "./components/LiveCallDescriptionView";
import {useEffect, useState} from "react";
import NotLiveView from "./components/NotLiveView";
import Snackbar from '@mui/material/Snackbar';
import ToggleLiveCallModal from "./components/ToggleLiveCallModal";
import {useAuth} from "../../../hooks/useAuth";
import ChatroomSidebarCollapsed from "../../../components/modals/ChatroomSidebarCollapsed";
import NetworkingAPI from "../../../helpers/NetworkingAPI";
import {doc, getDoc, getFirestore, onSnapshot, updateDoc} from "firebase/firestore";
import EditSessionInfoDialog from "./components/EditSesssionInfoDialog";
import MeetingComponent from "../../../components/agora/MeetingComponent";


const CHATROOMS_COLLECTION = "sessions"

function LiveCallPage() {

    const { getUser } = useAuth();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [commentsEnabled, setCommentsEnabled] = useState(false);
    const [isLive, setIsLive] = useState<boolean>(false);
    const [commentsOpen, setCommentsOpen] = useState(false);
    const [statusIndicatorOpen, setStatusIndicatorOpen] = useState(false);
    const [showToggleLiveCallModal, setShowToggleLiveCallModal] = useState(false);

    const [selectedChatroom, setSelectedChatroom] = useState<any>('6724d5e50503a109da170180');
    const [chatroomDetails, setChatroomDetails] = useState<any>(undefined);
    const [streamDetails, setStreamDetails] = useState<any>(undefined);
    const [hostDetails, setHostDetails] = useState<any>(undefined);

    const [user, setUser] = useState<any>(undefined);

    async function initializeChatroom() {
        try {
            let currentUser;
            if (getUser) {
                currentUser = await getUser();
            }

            if (!currentUser) {
                return console.log('NO_USER FOUND')
            }
            setUser(currentUser)
            if (!selectedChatroom) {
                return console.log('Chatroom not set')
            }
            const payload = {
                session_id: selectedChatroom
            }

            const dataResponse = await NetworkingAPI.fetchDataFullResponse('session/live-stream/details', 'POST', undefined, payload);
            const result = dataResponse?.response?.data;
            const _message = dataResponse?.message;
            if (result) {
                setStreamDetails(result?.stream_details)
                setHostDetails(result?.host_details)
                // setIsLive(result?.is_live ?? false)
                setChatroomDetails(result)
                return await handleDocInstantiation()
            }

        } catch (error) {
            console.log('error: ', error)
        }
    }

    function handleToggledLiveStatus() {
        toggledLiveCall()
    }

    async function handleDocInstantiation() {
        if (!selectedChatroom) {
            return console.log('Chatroom not set')
        }
        try {
            const firestore = getFirestore();
            if (!firestore) return;
            const docRef = doc(firestore, CHATROOMS_COLLECTION, selectedChatroom);
            const retrievedDoc = await getDoc(docRef);
            if (retrievedDoc.exists()) {
                const docData = retrievedDoc.data() ?? {comments_enabled: false}
                // @ts-ignore
                setIsLive(docData?.is_live ?? false)
                setCommentsEnabled(docData?.comments_enabled ?? false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function handleModalSelection() {
        let value = isLive;
        value = !isLive;
        try {
            const firestore = getFirestore();
            const docRef = doc(firestore, CHATROOMS_COLLECTION, selectedChatroom);
            // Update the specific field in the document
            await updateDoc(docRef, {
                ['is_live']: value,
            });
            setIsLive(value);
            return setShowToggleLiveCallModal(false)
        } catch (error) {
            console.error("Error updating document field: ", error);
            throw error; // Optional: re-throw the error for handling elsewhere
        }
    }

    function toggledLiveCall() {
        setShowToggleLiveCallModal(true)
        // setShowToggleLiveCallModal(false)
    }

    useEffect(() => {
        initializeChatroom()
    }, []);

    useEffect(() => {

    }, [showToggleLiveCallModal]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (selectedChatroom) {
            const firestore = getFirestore();
            if (!firestore) return;
            const docRef = doc(firestore, CHATROOMS_COLLECTION, selectedChatroom);
            const unsubscribe = onSnapshot( docRef, (snapshot) => {
                    if (snapshot.exists()) {
                        setIsLive(snapshot.data().is_live ?? false);
                    } else {
                        console.warn("Document does not exist.");
                    }
                },
                (error) => {
                    console.error("Error listening to document: ", error);
                }
            );
            return () => unsubscribe();
        }
    }, []);

    return(
        <div className={'flex flex-col w-[100%] h-[100%] flex-shrink bg-black flex-start'}>
            {/*<div className={'w-[100%] h-[90px] min-h-[90px] bg-white'}/>*/}
            {/*VIDEO HOLDER*/}
            <div className={'w-[100%] min-h-[100%] flex-col justify-start items-start bg-gray-800'}>
                {/*<div className={'w-full h-[90px]'}/>*/}
                <MeetingComponent/>
            </div>
            {screenWidth < 768 ? (
                <>
                    {!isLive && (
                        <div className={'absolute min-h-[300px] w-full top-0 bottom-0 left-0 right-0 bg-gray-800'}>
                            <NotLiveView/>
                        </div>
                    )}
                    {(isLive || getUser()?.permissions?.includes('admin')) ? (
                        <div className={'w-[100%] h-[100%] bg-black flex-shrink flex flex-col justify-end items-start'}>
                            <LiveCallDescriptionView mobile={true} liveStatus={isLive} toggled={handleToggledLiveStatus}
                                                     details={chatroomDetails} host={hostDetails}/>
                        </div>
                    ) : null}
                    {isLive ? (
                        <>
                            {commentsOpen ? (
                                <ChatroomSidebar onClose={() => setCommentsOpen(false)} toggledComments={() => {
                                }} selectedChatroom={selectedChatroom} defaultCommentsEnabled={commentsEnabled}/>
                            ) : (
                                <ChatroomSidebarCollapsed mobile={true} onOpen={() => setCommentsOpen(true)}/>
                            )}
                        </>
                    ) : null}

                    <ToggleLiveCallModal show={showToggleLiveCallModal} handleSelection={handleModalSelection}
                                         handleClose={() => setShowToggleLiveCallModal(false)} liveStatus={isLive}/>
                    <Snackbar
                        open={statusIndicatorOpen}
                        autoHideDuration={5000}
                        onClose={() => setStatusIndicatorOpen(false)}
                        message="You are now live"
                    />
                    {getUser().permissions?.includes('admin') && (
                        <EditSessionInfoDialog session_details={chatroomDetails}/>
                    )}
                </>
            ) : (
                <>
                    {!isLive && (
                        <div className={'absolute h-full w-full top-0 bottom-0 left-0 right-0 bg-gray-800'}>
                            <NotLiveView/>
                        </div>
                    )}
                    {(isLive || getUser()?.permissions?.includes('admin')) ? (
                        <div className={'w-[100%] h-[100%] bg-black flex-shrink flex flex-col justify-end items-start'}>
                            <LiveCallDescriptionView commentsShowing={false} liveStatus={isLive} toggled={handleToggledLiveStatus}
                                                     details={chatroomDetails} host={hostDetails}/>
                        </div>
                    ) : null}
                    {isLive ? (
                        <>
                            {commentsOpen ? (
                                <ChatroomSidebar onClose={() => setCommentsOpen(false)} toggledComments={() => {
                                }} selectedChatroom={selectedChatroom} defaultCommentsEnabled={commentsEnabled}/>
                            ) : (
                                <ChatroomSidebarCollapsed onOpen={() => setCommentsOpen(true)}/>
                            )}
                        </>
                    ) : null}

                    <ToggleLiveCallModal show={showToggleLiveCallModal} handleSelection={handleModalSelection}
                                         handleClose={() => setShowToggleLiveCallModal(false)} liveStatus={isLive}/>
                    <Snackbar
                        open={statusIndicatorOpen}
                        autoHideDuration={5000}
                        onClose={() => setStatusIndicatorOpen(false)}
                        message="You are now live"
                    />
                    {getUser().permissions?.includes('admin') && (
                        <EditSessionInfoDialog session_details={chatroomDetails}/>
                    )}
                </>
            )}
        </div>
    )

}

export default LiveCallPage;
