const Images = {

    MainLogo: require('./global/MainLogo.png'),
    MainLogoWhite: require('./global/LOGO_WHITE.png'),
    NavBarBackground: require('./global/BANNER.png'),
    NavBarBackground2: require('./global/BANNER_2.png'),
    CommentsIcon: require('./global/COMMENTS_ICON.png'),
    CommentsSendIcon: require('./global/COMMENTS_SEND.png'),
    DescriptionGradient: require('./global/Description_Gradient.png')

}

export default Images;
