import React, {CSSProperties, useEffect, useState} from "react";
import {
    AgoraRTCProvider,
    useJoin,
    useLocalCameraTrack,
    useLocalMicrophoneTrack,
    usePublish,
    useRTCClient,
    useRemoteAudioTracks,
    useRemoteUsers,
    RemoteUser,
    useClientEvent,
} from "agora-rtc-react";
import AgoraRTC, {IAgoraRTCClient, ILocalAudioTrack, ILocalVideoTrack} from "agora-rtc-sdk-ng";

const MeetingComponent = () => {

    const client = useRTCClient(AgoraRTC.createClient({ codec: "vp8", mode: "live", role: 'audience' }));
    const [channelName, setChannelName] = useState("1HOUSE_TEST");
    const [AppID, setAppID] = useState("f0e4e8a6defd4505a33dfc19b055a5e8");
    const [token, setToken] = useState("");
    const [inCall, setInCall] = useState(true);


    return (
        <div className={'flex-shrink h-full left-0 right-0 bottom-0 top-0 bg-black'}>
            <AgoraRTCProvider client={client}>
                <Videos client={client} channelName={channelName} AppID={AppID} token={token} />
            </AgoraRTCProvider>
        </div>
    );

}

function Videos(props: { client: IAgoraRTCClient, channelName: string; AppID: string; token: string }) {
    const { AppID, channelName, token, client } = props;
    // const { isLoading: isLoadingMic, localMicrophoneTrack } = useLocalMicrophoneTrack();
    // const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack();
    const remoteUsers = useRemoteUsers();
    const { audioTracks } = useRemoteAudioTracks(remoteUsers);

    const [count, setCount] = useState(0);
    const [initialized, setInitialized] = useState(false);

    // const client = useRTCClient();

    useClientEvent(client, "user-published", (user) => {
        console.log('USER_JOINED')
        setCount(count + 1);
    });

    useClientEvent(client, "user-unpublished", (user) => {
        console.log('USER_LEFT')
        setCount(count + 1);
    });

    useClientEvent(client, "published-user-list", (user) => {
        console.log('STATE_CHANGE')
        setCount(count + 1);
    });

    audioTracks.map((track) => track.play());

    // usePublish([localMicrophoneTrack, localCameraTrack]);

    const readyStatus = useJoin({
        appid: AppID,
        channel: channelName,
        token: token === "" ? null : token,

    }, true, client);

    useEffect(() => {
        console.log('UPDATED')
        console.log(readyStatus)

    }, [readyStatus]);



    // const deviceLoading = isLoadingMic || isLoadingCam;
    // if (deviceLoading) return <div style={styles.grid}>Loading devices...</div>;
    //
    // const deviceUnavailable = !localCameraTrack || !localMicrophoneTrack;
    // if (deviceUnavailable) return <div style={styles.grid}>Please allow camera and microphone permissions</div>;

    // useEffect(() => {
    //
    // }, [remoteUsers.length, count]);

    return (
        <div className={'w-[100%] h-[100%] flex flex-row bg-black'}>
            {/*<LocalVideoTrack track={localCameraTrack} play={true} style={styles.gridCell} />*/}
            {remoteUsers.map((user) => (
                <RemoteUser user={user} style={{height: '100%', width: remoteUsers.length * window.innerWidth}}/>
            ))}
        </div>
    );
}

const Controls = (props: { localMicrophoneTrack: ILocalAudioTrack; localCameraTrack: ILocalVideoTrack }) => {
    const {localMicrophoneTrack, localCameraTrack} = props;
    return (
        <div style={styles.btnContainer}>
            <button onClick={() => void localMicrophoneTrack.setMuted(!localMicrophoneTrack.muted)}>Mute Mic</button>
            <button onClick={() => void localCameraTrack.setMuted(!localCameraTrack.muted)}>Mute Cam</button>
        </div>
    );
};

const unit = "minmax(0, 1fr) ";
const styles = {
    grid: {
        backgroundColor: 'red',
        width: "100%",
        height: "100%",
        display: "grid",
    },
    gridCell: { height: "100%", width: "100%" },
    container: {
        display: "flex",
        flexDirection: "column" as CSSProperties["flexDirection"],
        flex: 1,
        justifyContent: "center",
    },
    btnContainer: {
        display: "flex",
        flexDirection: "row" as CSSProperties["flexDirection"],
        alignSelf: "center",
        width: "50%",
        justifyContent: "space-evenly",
    },
};


export default MeetingComponent;
