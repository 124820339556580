import SignUpForm from "./components/SignUpForm";
import {useEffect, useRef, useState} from "react";
import * as React from "react";
import Images from "../../../assets/images/Images";

function SignUpPage() {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [showBanner, setShowBanner] = useState(false);


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const divRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (divRef.current) {
            const scrollTop = divRef.current.scrollTop; // Current vertical scroll position
            if (scrollTop > 34) {
                setShowBanner(true)
            } else {
                setShowBanner(false)
            }
        }
    };

    return (
        <div ref={divRef} onScroll={handleScroll} className={`w-[100%] bg-transparent ${screenWidth < 768 ? 'h-auto items-start overflow-scroll' : 'h-[100%] items-center'} flex justify-center`}>
            <SignUpForm is_mobile={screenWidth < 768}/>
            { showBanner && (
                <div style={{zIndex: 21}}
                     className={'absolute bg-[#1A1A1A] h-[80px] w-full top-0 left-0 right-0 flex flex-row justify-between items-center px-[30px] shadow-md'}>
                    <img style={{cursor: 'pointer', opacity: 1}}  src={Images.NavBarBackground2} className={'absolute left-0 top-0 w-[100%] h-[80px] object-fill'}
                         alt={'1House Global'}/>
                    <img style={{cursor: 'pointer'}} src={Images.MainLogoWhite} className={'w-[114px] h-[34px]'}
                         alt={'1House Global'}/>
                </div>
            )}
        </div>
    )

}

export default SignUpPage;
