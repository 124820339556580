import * as React from 'react';
import {Person} from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import {VisibilityOff} from '@mui/icons-material';
import {Visibility} from '@mui/icons-material';
import {Smartphone} from '@mui/icons-material';
import {Mail} from '@mui/icons-material';
import {Lock} from '@mui/icons-material';
import { Pin } from '@mui/icons-material';
import { Event } from '@mui/icons-material';
import { EmojiEvents } from '@mui/icons-material';
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";
import Alert from '@mui/material/Alert';
import NetworkingAPI from "../../../../helpers/NetworkingAPI";
import {useAuth} from "../../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
// import dayjs from "dayjs"; // Import dayjs
import dayjs, { Dayjs } from 'dayjs';
import {SignUpDateSelectorModal} from "./SignUpDateSelectorModal";

export interface MainPageProps {
    is_mobile?: boolean
}

const SignUpForm = ({ is_mobile = false }: MainPageProps) => {

    const { setToken, setUser } = useAuth();

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [dateSelectorModalOpen, setDateSelectorModalOpen] = useState<boolean>(false);

    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(new Date()));

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [smartshipDate, setSmartshipDate] = useState('');
    const [upline, setUpline] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [error, setError] = useState('');


    async function handleSubmit() {
        setLoading(true);
        if (!email || !password) {
            return setError('Email and password is required')
        }

        const firstName = name.split(' ').slice(0, -1).join(' ');
        const lastName = name.split(' ').slice(-1).join(' ');

        if (!firstName || !lastName) {
            return setError('First and last name is required')
        }

        if (!mobile) {
            return setError('Please fill out all required fields')
        }

        if (confirmPassword !== password) {
            return setError('Please make sure your passwords match')
        }

        if (password.length < 7) {
            return setError('Password must be at least 8 characters long.')
        }

        if (customerID?.length < 7) {
            return setError('Customer ID must be 7 digits.')
        }

        try {
            const payload = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
                mobile: mobile,
                customer_id: customerID,
                smartship_date: selectedDate?.format('MM/DD/YYYY'),
                upline: upline
            }

            const dataResponse = await NetworkingAPI.fetchDataFullResponse('auth/sign-up', 'POST', undefined, payload);
            const result = dataResponse?.response?.data;
            const message = dataResponse?.response?.message;
            if (result) {
                const token = result?.token;
                const user = result?.user;
                if (token && user) {
                    setError('')
                    setToken(token)
                    setUser(user)
                    return window.location.reload()
                }
            } else {
                return setError(message ?? 'Failed')
            }
        } catch (error) {

        }
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleDateChange = (newValue?: dayjs.Dayjs | null) => {
        if (newValue) {
            setSelectedDate(newValue);
            setDateSelectorModalOpen(false)
        }
    };

    function handleNameChange(e: any) {
        setName(e.target.value);
    }

    function handleMobileChange(e: any) {
        setMobile(e.target.value);
    }

    function handleSmartshipChange(e: any) {
        setSmartshipDate(e.target.value);
    }

    function handleUplineChange(e: string) {
        setUpline(e ?? '');
    }

    function handleCustomerIDChange(e: any) {
        const inputValue = e.target.value;
        if (inputValue.length <= 7) {
            setCustomerID(inputValue);
        }
    }

    function handleEmailChange(e: any) {
        setEmail(e.target.value);
    }

    function handlePasswordChange(e: any) {
        setPassword(e.target.value);
    }

    function handleConfirmPasswordChange(e: any) {
        setConfirmPassword(e.target.value);
    }

    return (
        <>
            <div style={{zIndex: 4, backgroundColor: 'rgba(0,0,0,0.5)'}}
                 className={`w-[100%] min-h-[100vh] h-auto bg-transparent ${is_mobile ? 'justify-start items-center pb-[40px]' : 'justify-center items-center'} flex flex-col  ${is_mobile ? 'px-[20px]' : ''}`}>
                {is_mobile && (
                    <div className={'w-full min-h-[90px]'}/>
                )}
                <div className={`px-[30px] bg-white rounded-[20px] pt-[30px] pb-[40px] ${is_mobile ? 'mt-[0px]' : ''}`}>
                    <div className={'flex flex-col items-center justify-center max-w-[390px] space-y-[5px]'}>
                        <p className={`font-bold text-black ${is_mobile ? 'text-[25px]' : 'text-[30px]'} text-center`}>Get
                            Started</p>
                        <p className={`font-medium text-gray-500 ${is_mobile ? 'text-[15px]' : 'text-[17px]'} text-center`}>Start joining the 1House live session education and mentorship calls</p>
                    </div>

                    <div className={'flex flex-col items-center justify-center max-w-[390px] space-y-[10px] mt-[20px]'}>
                        {error ? (
                            <Alert className={'w-full'} severity="error">{error}</Alert>
                        ) : null}

                        {/*NAME*/}
                        <div
                            className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                            <Person sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                            <TextField
                                required={true}
                                type={'text'}
                                onChange={(e) => handleNameChange(e)}
                                InputProps={{disableUnderline: true}}
                                sx={{width: '100%'}}
                                id="name"
                                label="Full Name"
                                variant="standard"
                                className={'mb-[5px]'}/>
                        </div>

                        {/*EMAIL*/}
                        <div
                            className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                            <Mail sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                            <TextField
                                required={true}
                                type={'email'}
                                onChange={(e) => handleEmailChange(e)}
                                InputProps={{disableUnderline: true}}
                                sx={{width: '100%'}}
                                id="email"
                                label="Email Address"
                                variant="standard"
                                className={`mb-[5px] font-normal ${is_mobile ? 'text-[10px]' : ''} text-center`}/>
                        </div>

                        {/*SMARTSHIP DATE*/}

                        <div
                            className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                            <Event sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                            <TextField
                                required={true}
                                onClick={() => setDateSelectorModalOpen(true)}
                                type={'text'}
                                onChange={(e) => handleSmartshipChange(e)}
                                InputProps={{disableUnderline: true}}
                                sx={{width: '100%'}}
                                id="smartship"
                                defaultValue={selectedDate?.format('MM/DD/YYYY')}
                                value={selectedDate?.format('MM/DD/YYYY')}
                                label="Smartship Date"
                                variant="standard"
                                className={`mb-[5px] font-normal ${is_mobile ? 'text-[10px]' : ''} text-center`}/>
                        </div>


                        {/*UPLINE CHAMPION*/}
                        <div
                            className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                            <EmojiEvents sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                            <Select
                                className={`mb-[5px] text-black font-normal ${is_mobile ? 'text-[10px]' : ''} top-[10px] w-full `}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={upline}
                                label="Age"
                                sx={{width: '100%', height: 43, boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }}}
                                onChange={(e) => handleUplineChange(e?.target?.value)}
                            >
                                <MenuItem value={''}>Make a selection</MenuItem>
                                {DEFAULT_OPTIONS?.map((option) => (
                                    <MenuItem value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                            {/*<Autocomplete*/}
                            {/*    style={{ zIndex: 200}}*/}
                            {/*    className={`mb-[5px] font-normal ${is_mobile ? 'text-[10px]' : ''} text-center w-full flex flex-col`}*/}
                            {/*    disablePortal*/}
                            {/*    options={options}*/}
                            {/*    disabled={false}*/}
                            {/*    sx={{ width: '100%', zIndex: 200}}*/}
                            {/*    onInputChange={(e, value) => {*/}
                            {/*        console.log(value)*/}
                            {/*        handleUplineChange(value)*/}
                            {/*    }}*/}
                            {/*    renderInput={(params) => (*/}
                            {/*        <TextField*/}
                            {/*            {...params}*/}
                            {/*            required={true}*/}
                            {/*            type={'text'}*/}
                            {/*            InputProps={{disableUnderline: true}}*/}
                            {/*            sx={{width: '100%', height: '43px'}}*/}
                            {/*            id="upline"*/}
                            {/*            label="Who is your upline champion?"*/}
                            {/*            variant="standard"*/}
                            {/*            />*/}
                            {/*    )}*/}
                            {/*/>*/}
                        </div>

                        {/*ID NUMBER*/}
                        <div
                            className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                            <Pin sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                            <TextField
                                type={'number'}
                                onChange={(e) => handleCustomerIDChange(e)}
                                InputProps={{disableUnderline: true}}
                                sx={{width: '100%'}}
                                id="email"
                                label="ID Number"
                                variant="standard"
                                value={customerID}
                                inputProps={{ maxLength: 7, minLength: 7 }}
                                className={`mb-[5px] font-normal ${is_mobile ? 'text-[10px]' : ''} text-center`}/>
                        </div>

                        {/*MOBILE*/}
                        <div
                            className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                            <Smartphone sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                            <TextField
                                type={'tel'}
                                onChange={(e) => handleMobileChange(e)}
                                InputProps={{disableUnderline: true}}
                                sx={{width: '100%'}}
                                id="email"
                                label="Mobile Number"
                                variant="standard"
                                className={'mb-[5px]'}/>
                        </div>

                        <div
                            className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                            <Lock sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                            <TextField onChange={(e) => handlePasswordChange(e)}
                                       type={showPassword ? 'text' : 'password'}
                                       InputProps={{
                                           disableUnderline: true, endAdornment: (
                                               <InputAdornment position="end">
                                                   <IconButton
                                                       size={"small"}
                                                       aria-label="toggle password visibility"
                                                       onClick={handleClickShowPassword}
                                                       // onMouseDown={handleMouseDownPassword}
                                                   >
                                                       {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                   </IconButton>
                                               </InputAdornment>)
                                       }}
                                       sx={{width: '100%'}}
                                       id="password"
                                       label="Password"
                                       variant="standard"

                                       className={'mb-[5px]'}/>
                        </div>
                        <div
                            className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                            <Lock sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                            <TextField onChange={(e) => handleConfirmPasswordChange(e)}
                                       type={showConfirmPassword ? 'text' : 'password'}
                                       InputProps={{
                                           disableUnderline: true, endAdornment: (
                                               <InputAdornment position="end">
                                                   <IconButton
                                                       size={"small"}
                                                       aria-label="toggle password visibility"
                                                       onClick={handleClickShowConfirmPassword}
                                                       // onMouseDown={handleMouseDownConfirmPassword}
                                                   >
                                                       {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                                   </IconButton>
                                               </InputAdornment>)
                                       }}
                                       sx={{width: '100%'}}
                                       id="confirm-password"
                                       label="Confirm Password"
                                       variant="standard"

                                       className={'mb-[5px]'}/>
                        </div>
                    </div>
                    <div className={'flex flex-col items-center justify-center max-w-[390px] mt-[15px]'}>
                        <div onClick={() => handleSubmit()} style={{cursor: 'pointer'}}
                             className={'w-full bg-black rounded-[18px] px-[15px] py-[16px] items-center justify-center'}>
                            <p className={'font-semibold text-white text-[15px] text-center'}>Get Started</p>
                        </div>
                    </div>
                    <div className={'flex flex-col items-center justify-center max-w-[390px] mt-[20px]'}>
                        <a style={{cursor: 'pointer'}} href={'/auth/sign-in'}>
                            <p className={`font-bold text-[#0F7B28] ${is_mobile ? 'text-[14px]' : 'text-[15px]'} text-center underline`}>Already
                                have an
                                account? Login Now</p>
                        </a>
                    </div>
                </div>
                {is_mobile && (
                    <div className={'w-full min-h-[90px]'}/>
                )}
            </div>
            <SignUpDateSelectorModal defaultValue={selectedDate} modal_open={dateSelectorModalOpen} handleSelection={(e) => handleDateChange(e)} handleClose={() => setDateSelectorModalOpen(false)} />
        </>
    )

}

const DEFAULT_OPTIONS = [
    "JC Morales",
    "RJ Deiondre",
    "Carl Smith",
    "Brycen Thomas",
    "Baïla Dia",
    "Imran Ritchie",
    "Daniel Leo",
    "Edgar Santana",
    "Norman Love",
    "Richard Hall",
    "JC MORALES",
    "Joshua Stewart",
    "Nathan Samuel",
    "Bryce Thompson",
    "Beava Jay",
    "Jayden Birchfield",
    "Koliah Licon",
    "Anthony Miller",
    "Carl Wesley",
    "Larah Kaplan",
    "Andrea June",
    "Richard Hall",
    "Fearzy Hoet",
    "Malachi Turner",
    "Morgan Simpson",
    "Seany Thomas",
    "Makada Martin",
    "Niko Houston",
    "Ameenah Rasheed",
    "Tyisha Stocking",
    "Oladimeji Okunnuga",
    "Louis Scholtes",
    "Selmo Martins",
    "Bruno Rodrigues",
    "Yarwin Mujula",
    "Jalen Jones",
    "Abi Belilty",
    "Penny Thomson",
    "Te Ariki Walker",
    "Shadow Pene"
].sort((a, b) => a.localeCompare(b));

export default SignUpForm;
