import MessageBackgroundImage from '../../assets/images/global/SENDER_MESSAGE_BACKGROUND.png';
import Switch from '@mui/material/Switch';
import Images from "../../assets/images/Images";
import {useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import NetworkingAPI from "../../helpers/NetworkingAPI";
import { Delete } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { getFirestore, collection, doc, deleteDoc, getDocs, addDoc, updateDoc, orderBy, query, onSnapshot } from "firebase/firestore";

interface SidebarProps {
    onClose: () => void,
    toggledComments: (value: boolean) => void,
    selectedChatroom: any,
    defaultCommentsEnabled: boolean
}

const CHATROOMS_COLLECTION = "sessions"
const MESSAGES_COLLECTION = 'messages'

const ChatroomSidebar = ({ onClose, toggledComments, selectedChatroom, defaultCommentsEnabled = false }: SidebarProps) => {

    const { getUser } = useAuth();

    const [ commentsEnabled, setCommentsEnabled] = useState(defaultCommentsEnabled);

    const backgroundImageUrl = "../../../assets/global/SENDER_MESSAGE_BACKGROUND.png";
    const label = { inputProps: { 'aria-label': 'Size switch demo' } };

    const [userSnapshots, setUserSnapshots] = useState<any[]>([]);

    const [messages, setMessages] = useState<any[]>([]);
    const [comment, setComment] = useState('');
    const [selectedAssets, setSelectedAssets] = useState<any[]>([]);
    const [chatroomDetails, setChatroomDetails] = useState<any>(undefined);

    const [chatroomId, setChatroomId] = useState<string | undefined>('test-chatroom-1');

    async function handleCommentsToggle(e: any) {
        const value = e.target.checked;
        try {
            const firestore = getFirestore();
            const docRef = doc(firestore, CHATROOMS_COLLECTION, selectedChatroom);
            await updateDoc(docRef, {
                'comments_enabled': value,
            });
            return setCommentsEnabled(value);
        } catch (error) {
            console.error("Error updating document field: ", error);
            throw error; // Optional: re-throw the error for handling elsewhere
        }
    }

    async function initializeChatroom() {
        try {
            if (!selectedChatroom) {
                return console.log('Chatroom not set')
            }
            const payload = {
                session_id: selectedChatroom
            }

            const dataResponse = await NetworkingAPI.fetchDataFullResponse('session/live-stream/details', 'POST', undefined, payload);
            const result = dataResponse?.response?.data;
            const _message = dataResponse?.message;
            if (result) {
                setChatroomId(selectedChatroom)
                setChatroomDetails(result)
                await initializePage()
            }

        } catch (error) {
            console.log(error)
        }
    }

    async function initializePage() {
        try {
            const dataResponse = await NetworkingAPI.fetchDataFullResponse('user/utility/snapshot', 'GET', undefined, undefined);
            const result = dataResponse?.response?.data;
            const _message = dataResponse?.message;
            if (result) {
                return setUserSnapshots(result ?? [])
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function handleClearChatroom() {

        console.log('TEE 1')
        if (!commentsEnabled) {
            return console.log('Comments disabled')
        }

        try {
            if (selectedChatroom) {
                const firestore = getFirestore()
                if (firestore) {
                    const firestore = getFirestore();
                    const querySnapshot = await getDocs(collection(firestore, CHATROOMS_COLLECTION, selectedChatroom, MESSAGES_COLLECTION));

                    if (querySnapshot.empty) {
                        console.log(`The collection "${CHATROOMS_COLLECTION}" is already empty.`);
                        return;
                    }
                    const deletePromises: any[] = [];
                    querySnapshot.forEach((document: any) => {
                        deletePromises.push(deleteDoc(doc(firestore, CHATROOMS_COLLECTION, selectedChatroom, MESSAGES_COLLECTION, document.id)));
                    });

                    await Promise.all(deletePromises); // Wait for all deletions
                    console.log(`All documents in the "${CHATROOMS_COLLECTION}" collection have been deleted.`);
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function handleSendComment() {
        if (!commentsEnabled) {
            return console.log('Comments disabled')
        }

        try {
            if (chatroomId) {
                if (comment && comment.trim()) {
                    const firestore = getFirestore()
                    if (firestore) {
                        let currentUser;
                        currentUser = getUser();
                        let downloadURLS: any[] = [];
                        if ((selectedAssets ?? []).length > 0) {
                            // TODO: UPLOAD ASSETS
                            // downloadURLS = await Promise.all((selectedAssets ?? []).map(async (asset) => {
                            //     const downloadURL = await ImageUploaderAPI.uploadImageURI(asset?.uri);
                            //     return {
                            //         type: 'photo',
                            //         url: downloadURL,
                            //     }
                            // }))
                        }
                        const firestore = getFirestore();
                        const messagesRef = collection(firestore, CHATROOMS_COLLECTION, chatroomId, MESSAGES_COLLECTION);

                        const messageData = {
                            message: comment,
                            timestamp: new Date().getTime(), // UTC timestamp
                            type: "text",
                            likes: [],
                            user: currentUser?._id,
                            multimedia: downloadURLS,
                        };

                        await addDoc(messagesRef, messageData);
                        const payload = {
                            chatroomId: chatroomDetails?._id,
                            content: ((comment ?? '') === '' && (selectedAssets ?? []).length > 0) ? 'Multimedia Message' : comment
                        }
                        await NetworkingAPI.fetchDataFullResponse('chatroom/messages/send', 'POST', undefined, payload);
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function handleDocInstantiation() {
        setCommentsEnabled(defaultCommentsEnabled)
    }

    useEffect(() => {
        initializeChatroom()
    }, []);

    useEffect(() => {

    }, [userSnapshots]);

    useEffect(() => {
        handleDocInstantiation()
    }, [defaultCommentsEnabled]);

    useEffect(() => {
        if (chatroomId) {
            const firestore = getFirestore();
            if (!firestore) return;

            const messagesRef = collection(firestore, CHATROOMS_COLLECTION, chatroomId, MESSAGES_COLLECTION);
            const messagesQuery = query(messagesRef, orderBy("timestamp", "desc"));

            const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
                const messagesData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMessages(messagesData.reverse());
            });

            // Cleanup subscription on component unmount
            return () => unsubscribe();
        }
    }, [userSnapshots]);

    useEffect(() => {
        if (chatroomId) {
            const firestore = getFirestore();
            if (!firestore) return;

            // Reference the document
            const docRef = doc(firestore, CHATROOMS_COLLECTION, chatroomId);

            // Set up the listener
            const unsubscribe = onSnapshot( docRef, (snapshot) => {
                    if (snapshot.exists()) {
                        setCommentsEnabled(snapshot.data().comments_enabled ?? false);
                    } else {
                        console.warn("Document does not exist.");
                    }
                },
                (error) => {
                    console.error("Error listening to document: ", error);
                }
            );

            // Return the unsubscribe function
            return () => unsubscribe();
        }
    }, []);

    function getSenderForMessage(item: any) {
        const targetSender = userSnapshots ? (userSnapshots?.filter((user) => user?._id === item?.user)?.length > 0 ? userSnapshots?.filter((user) => user?._id === item?.user)[0] : null) : null
        if (targetSender) {
            return targetSender
        } else {
            return undefined
        }
    }

    function timestampToDate(timestamp: number) {
        const date = new Date(timestamp);

        // Extract month, day, hours, and minutes
        const month = date.getMonth() + 1; // Months are 0-indexed
        const day = date.getDate();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const isPM = hours >= 12;

        // Convert to 12-hour format
        hours = hours % 12 || 12;

        // Format minutes to always show two digits
        const formattedMinutes = minutes.toString().padStart(2, "0");

        // Format the result as MM/DD h:mmAM/PM
        return `${month}/${day} ${hours}:${formattedMinutes}${isPM ? " PM" : " AM"}`;
    }

    return(
        <div className={'absolute w-[373px] safe-area max-h-[100%] top-0 bottom-0 right-0 flex-col flex justify-between overflow-hidden'}>
            <div style={{ zIndex: 15, backgroundColor: 'rgba(0,0,0,0.35)'}} className={'absolute w-[373px] top-0 bottom-0 right-0 flex-col flex justify-between backdrop-blur-sm'}/>
            <div className={'w-[100%] h-[90px] min-h-[90px] bg-black'}/>
            <div style={{ zIndex: 16}}
                className={'w-[100%] max-h-auto flex-shrink px-[24px] pt-[24px] pb-[50px] flex flex-col justify-end'}>
                <div className={'w-full flex flex-row items-center justify-between space-x-[10px] flex-nowrap'}>
                    <div style={{ cursor: 'pointer' }} onClick={() => onClose()} className={'flex flex-row items-center justify-start space-x-[10px] flex-nowrap'}>
                        <img src={Images.CommentsIcon} className={'w-[30px] h-[30px] min-w-[30px] min-h-[30px]'}/>
                        <div className={'flex flex-col space-y-[-2px]'}>
                            <p className={'font-semibold text-white text-[17px]'}>Comments</p>
                            <p className={'font-regular text-white text-[12px]'}>Engage with the community</p>
                        </div>
                    </div>
                    {getUser()?.permissions?.includes('admin') && (
                        <div className={'flex flex-row items-center justify-end space-x-[0px] flex-nowrap'}>
                            <IconButton onClick={() => handleClearChatroom()} aria-label="delete">
                                <Delete color={'error'} />
                            </IconButton>
                            <Switch {...label} defaultChecked={commentsEnabled} onChange={(e) => handleCommentsToggle(e)} size="small"/>
                        </div>
                    )}
                </div>
                {commentsEnabled ? (
                    <div style={{
                        backgroundColor: 'rgba(245,245,245,0.20)',
                        maxHeight: 'calc(100vh - 300px)',
                        height: 'calc(100vh - 300px)',
                        overscrollBehavior: 'contain'
                    }}
                         className={'w-full h-full overflow-scroll scroll-auto rounded-[26px] mt-[20px] flex flex-col justify-end items-end py-[12px] flex-shrink backdrop-blur-md'}>
                        {messages.map((message, index) => (
                            <>
                                {message.user === getUser()?._id ? (
                                    <div className={'w-full flex flex-col pr-[14px] pl-[50px] py-[4px] items-end'}>
                                        <div style={{
                                            backgroundImage: `url(${MessageBackgroundImage})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            height: "100%",
                                            width: "100%",
                                        }}
                                             className={'flex flex-row px-[18px] py-[15px] justify-start bg-gray-800 rounded-[26px]'}>
                                            <div className={'flex flex-col justify-center items-start space-y-[2px]'}>
                                                <p className={'font-light text-white text-[11px]  text-right'}>{timestampToDate(message?.timestamp)}</p>
                                                <p className={'font-regular text-white text-[13px]'}>{message?.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={'w-full flex flex-col pl-[14px] pr-[50px] py-[4px] items-start'}>
                                        <div
                                            className={'flex flex-row px-[18px] py-[15px] justify-start bg-[#E8E8E8] rounded-[26px]'}>
                                            <div className={'flex flex-col justify-center items-start space-y-[5px]'}>
                                                <div className={'flex flex-row items-center justify-between space-x-[10px]'}>
                                                    <p className={'font-bold text-black text-[11px] text-left'}>{getSenderForMessage(message) ? getSenderForMessage(message)?.name : message?.user}</p>
                                                    <p className={'font-light text-gray-600 text-[11px] text-right'}>{timestampToDate(message?.timestamp)}</p>
                                                </div>

                                                <p className={'font-regular text-black text-[13px]'}>{message?.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                ) : (
                    <div style={{
                        backgroundColor: 'rgba(245,245,245,0.20)',
                        maxHeight: 'calc(100vh - 300px)',
                        height: 'calc(100vh - 300px)',
                        overscrollBehavior: 'contain'
                    }}
                         className={'w-full h-[100%] overflow-scroll scroll-auto rounded-[26px] mt-[20px] flex flex-col justify-center items-center py-[12px] flex-shrink'}>
                        <p className={'font-semibold text-white text-[18px]'}>Comments Disabled</p>
                    </div>
                )}
                <div
                    className={'w-full h-[54px] min-h-[54px] bg-white flex flex-row justify-start rounded-full overflow-hidden items-center pr-[3px] pl-[20px] mt-[20px] flex-nowrap'}>
                    <input onChange={(e) => setComment(e?.target?.value)} inputMode={'text'} disabled={!commentsEnabled} className={`w-full flex-shrink h-full ${commentsEnabled ? 'opacity-45' : 'opacity-100'}`} placeholder={'Type message here...'}/>
                    <div style={{ cursor: commentsEnabled ? 'pointer' : 'default' }} onClick={() => handleSendComment()} className={'w-[45px] min-w-[45px] h-[45px] min-h-[45px] bg-black rounded-full items-center justify-center flex'}>
                        <img style={{ opacity: commentsEnabled ? 1 : 0.5}} src={Images.CommentsSendIcon} className={'w-[20px] h-[20px] mr-[2px] mt-[2px]'} alt={'send'}/>
                    </div>
                </div>
            </div>
        </div>
    )

}

const DEMO_MESSAGES = [
    {id: "4", message: 'I’ve been waiting on this since MAY!', sender: {id: "me", name: "Dahmeyon McDonald"}},
    {id: "3", message: 'FIRE', sender: {id: "other", name: "Michael Strahan"}},
    {
        id: "2",
        message: 'Yes, it’s been solved. Since we have daily meeting to discuss everything',
        sender: {id: "other", name: "Dahmeyon McDonald"}
    },
    {id: "1", message: 'This call is going to be awesome. Let’s go Bryce', sender: {id: "me", name: "Alicia Padlock"}},
    {id: "4", message: 'I’ve been waiting on this since MAY!', sender: {id: "me", name: "Dahmeyon McDonald"}},
    {id: "3", message: 'FIRE', sender: {id: "other", name: "Michael Strahan"}},
    {
        id: "2",
        message: 'Yes, it’s been solved. Since we have daily meeting to discuss everything',
        sender: {id: "other", name: "Dahmeyon McDonald"}
    },
    {id: "1", message: 'This call is going to be awesome. Let’s go Bryce', sender: {id: "me", name: "Alicia Padlock"}},
    {
        id: "2",
        message: 'Yes, it’s been solved. Since we have daily meeting to discuss everything',
        sender: {id: "other", name: "Dahmeyon McDonald"}
    },
    {id: "1", message: 'This call is going to be awesome. Let’s go Bryce', sender: {id: "me", name: "Alicia Padlock"}}
]

export default ChatroomSidebar
