interface Props {
    users: any[]
}

const UserAnalyticsCard = ({ users }: Props) => {

    return(
        <div className={'w-full bg-black flex flex-row justify-start items-center'}>
            <div className={'flex flex-col items-start justify-center h-full flex-shrink w-full px-[30px] py-[30px]'}>
                <p className={'font-bold text-[28px] text-white'}>Overview - Platform Users</p>
                <p className={'font-regular text-[13px] text-white'}>Showing total number of enrolled members</p>
            </div>
            <div className={'flex flex-col items-start justify-center h-full border-l-[1px] border-l-[#BFBFBF] px-[30px] py-[30px]'}>
                <p className={'font-regular text-[13px] text-white text-nowrap'}>Overall Active</p>
                <p className={'font-black text-[28px] text-white'}>{users?.length}</p>
            </div>
            <div className={'flex flex-col items-start justify-center h-full border-l-[1px] border-l-[#BFBFBF] px-[30px] py-[30px]'}>
                <p className={'font-regular text-[13px] text-white text-nowrap'}>Restricted Users</p>
                <p className={'font-black text-[28px] text-white'}>{users?.filter((user) => user?.status !== 1).length}</p>
            </div>
        </div>
    )

}

export default UserAnalyticsCard
