const NotLiveView = () => {

    return(
        <div className={'w-[100%] h-[100%] flex flex-col items-center justify-center'}>
            <p className={'font-semibold text-white text-[30px]'}>Live Has Not Started</p>
            <p className={'font-regular text-white text-[15px]'}>You will be permitted once the host starts the live</p>
        </div>
    )

}

export default NotLiveView;
