import Images from "../../../../assets/images/Images";
import Checkbox from '@mui/material/Checkbox';
import {RadioButtonUnchecked} from '@mui/icons-material';
import {RadioButtonChecked} from '@mui/icons-material';
import React, {useEffect, useState} from "react";
import Avatar from "@mui/material/Avatar";
import {useAuth} from "../../../../hooks/useAuth";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface Props {
    liveStatus: boolean,
    toggled: (value: boolean) => void,
    details?: any,
    host?: any,
    mobile?: boolean,
    commentsShowing?: boolean
}

const LiveCallDescriptionView = ({ commentsShowing, liveStatus, toggled, details, host, mobile = false }: Props) => {

    const { getUser } = useAuth();

    const [currentValue, setCurrentValue] = useState<boolean>(liveStatus);

    function handleToggle(e: any) {
        console.log(e.target.checked);
        toggled(e.target.checked);
    }

    useEffect(() => {
        setCurrentValue(liveStatus)
    }, [liveStatus]);

    return (
        <div className={`absolute bottom-0 left-0 right-0 h-auto px-[30px] pt-[30px] ${mobile ? 'pb-[30px]' : 'pb-[50px]'}`}>
            <img src={Images.DescriptionGradient} className={'absolute h-[100%] w-full bottom-0 left-0 right-0'} alt={''}/>
            <div style={{zIndex: 11}} className={`${mobile ? 'w-[1000%]': 'w-[50%]'}`}>
                {getUser()?.permissions?.includes('admin') && (
                    <div style={{zIndex: 13}} className={'flex flex-row justify-start mb-[20px]'}>
                        <div style={{zIndex: 13, backgroundColor: 'rgba(255,255,255,0.3)'}}
                             className={'pl-[5px] pr-[17px] py-[0px] flex flex-row items-center justify-start rounded-full border-white border-[1px]'}>
                            <Checkbox style={{zIndex: 13}}
                                      {...label}
                                      onChange={(e) => handleToggle(e)}
                                      checked={currentValue}
                                      icon={<RadioButtonUnchecked/>}
                                      color={liveStatus ? 'error' : 'info'}
                                      checkedIcon={<RadioButtonChecked/>}
                            />
                            <p className={`font-medium ${mobile ? 'text-[12px]' : 'text-[15px]'} text-white`}>{liveStatus ? "You're live! Tap the icon to end" : "Tap the icon to start live"}</p>
                        </div>
                    </div>
                )}
                <div style={{zIndex: 13}} className={'flex flex-col space-y-[-5px]'}>
                    <p style={{zIndex: 13}}
                       className={`font-black text-white ${mobile ? 'text-[20px]' : 'text-[30px]'}`}>{details ? details?.title : 'Not Yet Started'}</p>
                    <div className={'flex flex-row justify-start items-center space-x-[8px] pt-[10px]'}>
                        <Avatar style={mobile ? {zIndex: 13, height: 20, width: 20} : {zIndex: 13, height: 24, width: 24}} alt={host?.name}
                                src={host?.photo ? host?.photo : null} className={'border-white border-[2px]'}/>
                        <p style={{zIndex: 13}}
                           className={`font-medium text-white ${mobile ? 'text-[13px]' : 'text-[16px]'}`}>{host ? host?.name : 'Unknown'}</p>
                    </div>
                </div>
                <div style={{zIndex: 13}} className={'w-[100%] flex flex-col pt-[10px]'}>
                    {(details && details?.description) && (
                        <div style={{zIndex: 13, backgroundColor: 'rgba(255,255,255,0.23)'}}
                             className={'w-full h-[0.5px] mb-[5px]'}/>
                    )}
                    <p style={{zIndex: 13}}
                       className={'font-regular text-white text-[16px]'}>{details ? details?.description : ''}</p>
                </div>
            </div>
        </div>
    )

}

export default LiveCallDescriptionView
