import UsersTable from "./components/UsersTable";
import UserAnalyticsCard from "./components/UserAnalyticsCard";
import NetworkingAPI from "../../../helpers/NetworkingAPI";
import {useEffect, useState} from "react";
import {useAuth} from "../../../hooks/useAuth";

export interface UserData {
    id: string;
    name: string;
    email: string;
    mobile: string;
    username: string;
    status: number;
    customer_id: string;
    upline: string;
    smartship: string
}

function createData(
    id: string,
    name: string,
    email: string,
    mobile: string,
    username: string,
    status: number,
    customer_id: string,
    upline: string,
    smartship: string
): UserData {
    return {
        id,
        name,
        email,
        mobile,
        username,
        status,
        customer_id,
        upline,
        smartship
    };
}


const UsersPage = () => {

    const { getUser } = useAuth();

    const [users, setUsers] = useState<UserData[]>([]);
    const [count, setCount] = useState(0);

    async function initializePage() {
        try {
            const payload = {
                user_id: getUser()?._id
            }
            const dataResponse = await NetworkingAPI.fetchDataFullResponse('user/admin/members', 'POST', undefined, payload)
            const result = dataResponse?.response?.data;
            if (result) {
                const parsedUsers = result?.map((user: any) => {
                    console.log(user);
                    return createData(user?._id, user?.name, user?.email, user?.mobile, user?.username, user?.status, user?.customer_id ?? '', user?.upline_champion ?? '', user?.smartship_date ?? '');
                })
                setUsers(parsedUsers)
                setCount(count + 1)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        initializePage()
    }, [])

    useEffect(() => {
        //
    }, [count]);

    return(
        <>
            <div className={'flex flex-col w-[100%] h-[100%] flex-shrink bg-green-400 flex-start'}>
                {/*VIDEO HOLDER*/}
                <div className={'h-full w-full top-0 bottom-0 left-0 right-0 bg-gray-800 px-[30px] py-[30px]'}>
                    <UserAnalyticsCard users={users} />
                    <UsersTable rows={users} count={count} handleReload={() => initializePage()}/>
                </div>
            </div>
        </>
    )
}

export default UsersPage;
