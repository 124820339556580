import {useAuth} from "../../../../hooks/useAuth";
import {useState} from "react";
import NetworkingAPI from "../../../../helpers/NetworkingAPI";
import Alert from "@mui/material/Alert";
import {Mail} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import * as React from "react";

interface Props {
    resetId?: any,
    is_mobile?: boolean,
    startLoading: () => void,
    endLoading: () => void,
    proceedToNext: () => void
}

const VerifyAuthCodeView = ({ resetId, is_mobile, startLoading, endLoading, proceedToNext }: Props) => {

    const { setUser, setToken } = useAuth();

    const [resetCode, setResetCode] = useState('');

    const [error, setError] = useState('');

    async function handleSubmit() {
        // navigate('/portal')

        if (!resetCode || !resetId) {
            return setError('Please specify all required fields')
        }
        startLoading()
        try {
            const payload = {
                resetId: resetId,
                code: resetCode,
            }

            const dataResponse = await NetworkingAPI.fetchDataFullResponse('auth/forgot-password/validate', 'POST', undefined, payload);
            const result = dataResponse?.response?.data;
            const message = dataResponse?.response?.message;
            if (result) {
                setError('')
                setResetCode('')
                endLoading()
                return proceedToNext();
            } else {
                endLoading()
                return setError(message ?? 'Failed')
            }
        } catch (error) {
            endLoading()
            return setError('An unexpected error occurred')
        }
    }

    function handleAuthCodeChange(e: any) {
        setResetCode(e.target.value);
    }

    function handleResendCode(e: any) {
        e.preventDefault();
        console.log('CLICKED RESEND')
    }

    return (
        <div className={`px-[30px] bg-white rounded-[20px] pt-[30px] pb-[40px] ${is_mobile ? 'mt-[0px]' : ''}`}>
            <div className={'flex flex-col items-center justify-center max-w-[390px] space-y-[5px]'}>
                <p className={`font-bold text-black ${is_mobile ? 'text-[25px]' : 'text-[30px]'} text-center`}>Enter
                    Reset Code</p>
                <p className={`font-medium text-gray-500 ${is_mobile ? 'text-[15px]' : 'text-[17px]'} text-center`}>We
                    sent you a reset code to your email. It may take a few minutes to receive your code.</p>
            </div>

            <div className={'flex flex-col items-center justify-center max-w-[390px] space-y-[10px] mt-[20px]'}>
                {error ? (
                    <Alert className={'w-full'} severity="error">{error}</Alert>
                ) : null}
                <div
                    className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                    <Mail sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                    <TextField
                        onChange={(e) => handleAuthCodeChange(e)}
                        InputProps={{disableUnderline: true}}
                        sx={{width: '100%'}}
                        id="reset"
                        label="Reset Code"
                        variant="standard"
                        className={'mb-[5px]'}/>
                </div>
            </div>
            <div className={'flex flex-col items-end justify-center max-w-[390px] mt-[10px]'}>
                <a onClick={(e) => handleResendCode(e)} style={{cursor: 'pointer'}} href={'/'}>
                    <p className={'font-semibold text-gray-500 text-[15px] text-right'}>Resend Code</p>
                </a>
            </div>
            <div className={'flex flex-col items-center justify-center max-w-[390px] mt-[15px]'}>
                <div onClick={() => handleSubmit()} style={{cursor: 'pointer'}}
                     className={'w-full bg-black rounded-[18px] px-[15px] py-[16px] items-center justify-center'}>
                    <p className={'font-semibold text-white text-[15px] text-center'}>Continue</p>
                </div>
            </div>
            <div className={'flex flex-col items-center justify-center max-w-[390px] mt-[20px]'}>
                <a style={{cursor: 'pointer'}} href={'/auth/sign-up'}>
                    <p className={`font-bold text-[#0F7B28] ${is_mobile ? 'text-[14px]' : 'text-[15px]'} text-center underline`}>Sign
                        Up for 1House
                        Live
                        Now</p>
                </a>
            </div>
        </div>
    )

}

export default VerifyAuthCodeView
