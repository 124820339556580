import {Outlet} from "react-router-dom";
import AuthHeader from "./AuthHeader";
import ReactPlayer from 'react-player'
import Videos from "../../assets/videos/Videos";
import {useEffect, useState} from "react";

const AuthLayout = ({children}: any) => {

    const [showHeaderBackground, setShowHeaderBackground] = useState(false);

    const [offset, setOffset] = useState(0);

    return(
        <div className={'w-[100vw] h-[100vh] min-w-[100vw] min-h-[100vh] bg-black flex flex-col justify-between'}>
            <ReactPlayer
                playsinline={true}
                playing={true}
                muted={true}
                height={'100%'}
                width={'100%'}
                style={{position: 'absolute', objectFit: 'cover', top: 0, left: 0, right: 0, bottom: 0}}
                autoPlay={true}
                controls={false}
                loop={true}
                config={{ file: { attributes: { style: { width: '100%', height: '100%', objectFit: 'cover' }, autoplay: true } }}}
                url={Videos.AuthBackgroundVideo}
                className={'absolute top-0 bottom-0 left-0 right-0 min-h-[100vh] min-w-[100vw] bg-black object-cover'}/>
            <div />
            <AuthHeader showHeaderBackground={showHeaderBackground}/>
            <main style={{ zIndex: 3 }} className={'w-[100vw] h-[100vh] min-w-[100vw] min-h-[100vh] bg-transparent flex flex-col justify-between'}>
                <Outlet/>
            </main>

        </div>
    )
}

export default AuthLayout;
