const NotFoundPage = () => {

    return(
        <>
        </>
    )

}

export default NotFoundPage;
