import {useAuth} from "../../../../hooks/useAuth";
import {useState} from "react";
import NetworkingAPI from "../../../../helpers/NetworkingAPI";
import Alert from "@mui/material/Alert";
import {Lock, Mail, Visibility, VisibilityOff} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";


interface Props {
    resetId?: any,
    is_mobile?: boolean,
    startLoading: () => void,
    endLoading: () => void,
    proceedToNext: () => void
}

const ResetPasswordView = ({ resetId, is_mobile, startLoading, endLoading, proceedToNext }: Props) => {

    const { setUser, setToken } = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('');

    async function handleSubmit() {
        // navigate('/portal')

        if (!confirmPassword || !password || !resetId) {
            return setError('Please specify all required fields')
        }

        if (confirmPassword !== password) {
            return setError('Passwords do not match')
        }

        startLoading()
        try {
            const payload = {
                resetId: resetId,
                newPassword: password,
            }

            const dataResponse = await NetworkingAPI.fetchDataFullResponse('auth/forgot-password/reset', 'POST', undefined, payload);
            // console.log(dataResponse)
            const result = dataResponse?.response?.data;
            const message = dataResponse?.response?.message;
            if (result) {
                const token = result?.token;
                const user = result?.user;
                if (token && user) {
                    setError('')
                    setToken(token)
                    setUser(user)
                    setPassword('')
                    setConfirmPassword('')
                    endLoading()
                    return proceedToNext();
                }

            } else {
                endLoading()
                return setError(message ?? 'Failed')
            }
        } catch (error) {
            endLoading()
            return setError('An unexpected error occurred')
        }
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
    const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    function handlePasswordChange(e: any) {
        console.log(e.target.value);
        setPassword(e.target.value);
    }

    function handleConfirmPasswordChange(e: any) {
        console.log(e.target.value);
        setConfirmPassword(e.target.value);
    }

    return (
        <div className={`px-[30px] bg-white rounded-[20px] pt-[30px] pb-[40px] ${is_mobile ? 'mt-[0px]' : ''}`}>
            <div className={'flex flex-col items-center justify-center max-w-[390px] space-y-[5px]'}>
                <p className={`font-bold text-black ${is_mobile ? 'text-[25px]' : 'text-[30px]'} text-center`}>Reset Your Password</p>
                <p className={`font-medium text-gray-500 ${is_mobile ? 'text-[15px]' : 'text-[17px]'} text-center`}>Need help accessing your 1House Global Account? Don't worry we're here to help</p>
            </div>

            <div className={'flex flex-col items-center justify-center max-w-[390px] space-y-[10px] mt-[20px]'}>
                {error ? (
                    <Alert className={'w-full'} severity="error">{error}</Alert>
                ) : null}
                <div
                    className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                    <Lock sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                    <TextField onChange={(e) => handlePasswordChange(e)}
                               type={showPassword ? 'text' : 'password'}
                               InputProps={{
                                   disableUnderline: true, endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton
                                               size={"small"}
                                               aria-label="toggle password visibility"
                                               onClick={handleClickShowPassword}
                                               onMouseDown={handleMouseDownPassword}
                                           >
                                               {showPassword ? <Visibility/> : <VisibilityOff/>}
                                           </IconButton>
                                       </InputAdornment>)
                               }}
                               sx={{width: '100%'}}
                               id="input-with-sx"
                               label="Password"
                               variant="standard"

                               className={'mb-[5px]'}/>
                </div>
                <div
                    className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                    <Lock sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                    <TextField onChange={(e) => handleConfirmPasswordChange(e)}
                               type={showConfirmPassword ? 'text' : 'password'}
                               InputProps={{
                                   disableUnderline: true, endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton
                                               size={"small"}
                                               aria-label="toggle password visibility"
                                               onClick={handleClickShowConfirmPassword}
                                               onMouseDown={handleMouseDownConfirmPassword}
                                           >
                                               {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                           </IconButton>
                                       </InputAdornment>)
                               }}
                               sx={{width: '100%'}}
                               id="input-with-sx"
                               label="Password"
                               variant="standard"

                               className={'mb-[5px]'}/>
                </div>
            </div>
            <div className={'flex flex-col items-center justify-center max-w-[390px] mt-[15px]'}>
                <div onClick={() => handleSubmit()} style={{cursor: 'pointer'}}
                     className={'w-full bg-black rounded-[18px] px-[15px] py-[16px] items-center justify-center'}>
                    <p className={'font-semibold text-white text-[15px] text-center'}>Reset My Password</p>
                </div>
            </div>
            <div className={'flex flex-col items-center justify-center max-w-[390px] mt-[20px]'}>
                <a style={{cursor: 'pointer'}} href={'/auth/sign-up'}>
                    <p className={`font-bold text-[#0F7B28] ${is_mobile ? 'text-[14px]' : 'text-[15px]'} text-center underline`}>Sign
                        Up for 1House
                        Live
                        Now</p>
                </a>
            </div>
        </div>
    )

}

export default ResetPasswordView
