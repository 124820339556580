import * as React from 'react';
import TextField from '@mui/material/TextField';
import {VisibilityOff} from '@mui/icons-material';
import {Visibility} from '@mui/icons-material';
import {Mail} from '@mui/icons-material';
import {Lock} from '@mui/icons-material';
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useState} from "react";
import Alert from '@mui/material/Alert';
import NetworkingAPI from "../../../../helpers/NetworkingAPI";
import {useAuth} from "../../../../hooks/useAuth";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {MainPageProps} from "../../sign-up/components/SignUpForm";

const SignInForm = ({ is_mobile }: MainPageProps) => {

    const { setUser, setToken } = useAuth();

    const [loading, setLoading] = React.useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('');

    async function handleSubmit() {
        // navigate('/portal')
        if (!email || !password) {
            return setError('Email and password is required')
        }

        setLoading(true);

        try {
            const payload = {
                email: email,
                password: password,
            }

            const dataResponse = await NetworkingAPI.fetchDataFullResponse('auth/sign-in', 'POST', undefined, payload);
            const result = dataResponse?.response?.data;
            const message = dataResponse?.response?.message;
            if (result) {
                const token = result?.token;
                const user = result?.user;
                if (token && user) {
                    setError('')
                    setToken(token)
                    setUser(user)
                    setLoading(false)
                    return window.location.reload()
                }

            } else {
                setLoading(false);
                return setError(message ?? 'Failed')
            }
        } catch (error) {
            setLoading(false);
            return setError('An unexpected error occurred')
        }
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    function handlePasswordChange(e: any) {
        setPassword(e.target.value);
    }

    function handleEmailChange(e: any) {
        setEmail(e.target.value);
    }

    return (
        <div style={{ zIndex: 4, backgroundColor: 'rgba(0,0,0,0.5)'}} className={`w-[100%] min-h-[100vh] h-auto bg-transparent ${is_mobile ? 'justify-start items-center pb-[40px]' : 'justify-center items-center'} flex flex-col  ${is_mobile ? 'px-[20px]' : ''}`}>
            {is_mobile && (
                <div className={'w-full min-h-[90px]'} />
            )}
            <div className={`px-[30px] bg-white rounded-[20px] pt-[30px] pb-[40px] ${is_mobile ? 'mt-[0px]' : ''}`}>
                <div className={'flex flex-col items-center justify-center max-w-[390px] space-y-[5px]'}>
                    <p className={`font-bold text-black ${is_mobile ? 'text-[25px]': 'text-[30px]'} text-center`}>Sign in with email</p>
                    <p className={`font-medium text-gray-500 ${is_mobile ? 'text-[15px]' : 'text-[17px]'} text-center`}>Start joining the 1House live session education and mentorship calls</p>
                </div>

                <div className={'flex flex-col items-center justify-center max-w-[390px] space-y-[10px] mt-[20px]'}>
                    {error ? (
                        <Alert className={'w-full'} severity="error">{error}</Alert>
                    ) : null}
                    <div
                        className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                        <Mail sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                        <TextField
                            onChange={(e) => handleEmailChange(e)}
                            InputProps={{disableUnderline: true}}
                            sx={{width: '100%'}}
                            id="email"
                            label="Email Address"
                            variant="standard"
                            className={'mb-[5px]'}/>
                    </div>
                    <div
                        className={'bg-[#F3F3F3] items-end justify-center flex flex-center w-full rounded-[15px] px-[15px] pb-[13px]'}>
                        <Lock sx={{color: 'action.active', mr: 1, mb: '5px'}}/>
                        <TextField onChange={(e) => handlePasswordChange(e)}
                                   type={showPassword ? 'text' : 'password'}
                                   InputProps={{
                                       disableUnderline: true, endAdornment: (
                                           <InputAdornment position="end">
                                               <IconButton
                                                   size={"small"}
                                                   aria-label="toggle password visibility"
                                                   onClick={handleClickShowPassword}
                                                   onMouseDown={handleMouseDownPassword}
                                               >
                                                   {showPassword ? <Visibility/> : <VisibilityOff/>}
                                               </IconButton>
                                           </InputAdornment>)
                                   }}
                                   sx={{width: '100%'}}
                                   id="input-with-sx"
                                   label="Password"
                                   variant="standard"

                                   className={'mb-[5px]'}/>
                    </div>
                </div>
                <div className={'flex flex-col items-end justify-center max-w-[390px] mt-[10px]'}>
                    <a style={{cursor: 'pointer'}} href={'/auth/forgot-password'}>
                        <p className={'font-semibold text-gray-500 text-[15px] text-right'}>Forgot password?</p>
                    </a>
                </div>
                <div className={'flex flex-col items-center justify-center max-w-[390px] mt-[15px]'}>
                    <div onClick={() => handleSubmit()} style={{cursor: 'pointer'}}
                         className={'w-full bg-black rounded-[18px] px-[15px] py-[16px] items-center justify-center'}>
                        <p className={'font-semibold text-white text-[15px] text-center'}>Sign In</p>
                    </div>
                </div>
                <div className={'flex flex-col items-center justify-center max-w-[390px] mt-[20px]'}>
                    <a style={{cursor: 'pointer'}} href={'/auth/sign-up'}>
                        <p className={`font-bold text-[#0F7B28] ${is_mobile ? 'text-[14px]' : 'text-[15px]'} text-center underline`}>Sign Up for 1House
                            Live
                            Now</p>
                    </a>
                </div>
            </div>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )

}

export default SignInForm;
